import { lazy } from 'react';

import IntroBlockContent from '../../content/IntroBlockContent.json';
import AboutMeBlockContent from '../../content/AboutMeBlockContent.json';
import WhenToStartBlockContent from '../../content/WhenToStartBlockContent.json';
import SessionsBlockContent from '../../content/SessionsBlockContent.json';
import ContactsBlockContent from '../../content/ContactsBlockContent.json';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const IntroBlock = lazy(() => import('../../components/content/IntroBlock'));
const AboutMeBlock = lazy(() => import('../../components/content/AboutMeBlock'));
const WhenToStartBlock = lazy(() => import('../../components/content/WhenToStartBlock'));
const SessionsBlock = lazy(() => import('../../components/content/SessionsBlock'));
const ContactsBlock = lazy(() => import('../../components/content/ContactsBlock'));

const Container = lazy(() => import('../../common/Container'));
const ScrollToTop = lazy(() => import('../../common/ScrollToTop'));

const Home = () => {
  return (
    <>
      <Header isTrainingsLayout={false} />
      <Container>
        <ScrollToTop />
        <IntroBlock
          title={IntroBlockContent.title}
          paragraphs={IntroBlockContent.paragraphs}
          id={IntroBlockContent.title}
        />
        <AboutMeBlock
          title={AboutMeBlockContent.title}
          paragraphs={AboutMeBlockContent.paragraphs}
          id={AboutMeBlockContent.title}
        />
        <WhenToStartBlock
          title={WhenToStartBlockContent.title}
          button={WhenToStartBlockContent.button}
          paragraphs={WhenToStartBlockContent.paragraphs}
          id={WhenToStartBlockContent.title}
        />
        <SessionsBlock
          title={SessionsBlockContent.title}
          paragraphs={SessionsBlockContent.paragraphs}
          id={SessionsBlockContent.title}
        />

        <ContactsBlock
          title={ContactsBlockContent.title}
          contacts={ContactsBlockContent.contacts}
          place={ContactsBlockContent.place}
          socialMedia={ContactsBlockContent.socialMedia}
          id={ContactsBlockContent.title}
        />
      </Container>
      <Footer />
    </>
  );
};

export default Home;
