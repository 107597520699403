import { lazy, Fragment, FunctionComponent } from 'react';
import { Row } from 'antd';
import * as S from './styles';

const Container = lazy(() => import('../../common/Container'));

export const Footer: FunctionComponent = () => {
  const year = new Date().getFullYear();
  return (
    <Fragment>
      <S.Extra>
        <Container>
          <Row
            data-cy="footer_copyright"
            justify="space-between"
            align="middle"
            style={{ paddingTop: '2rem' }}
          >
            <S.FooterContainer>&copy; {year} vidineskeliones.lt</S.FooterContainer>
          </Row>
        </Container>
      </S.Extra>
    </Fragment>
  );
};

export default Footer;
