import { lazy } from 'react';

import SessionsBlockContent from '../../content/SessionsBlockContent.json';
import TrainingsBlock from '../../components/content/TrainingsBlock';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const Container = lazy(() => import('../../common/Container'));
const ScrollToTop = lazy(() => import('../../common/ScrollToTop'));

const Trainings = () => {
  return (
    <>
      <Header isTrainingsLayout={true} />
      <Container>
        <ScrollToTop />

        <TrainingsBlock
          title={SessionsBlockContent.title}
          paragraphs={SessionsBlockContent.paragraphs}
          id={SessionsBlockContent.title}
        />
      </Container>
      <Footer />
    </>
  );
};

export default Trainings;
