import { useState, Fragment, lazy, FunctionComponent } from 'react';
import { Row, Col, Drawer } from 'antd';
import { CSSTransition } from 'react-transition-group';
import { withTranslation } from 'react-i18next';

import * as S from './styles';
import { HeaderProps } from './types';
import IntroBlockContent from '../../content/IntroBlockContent.json';
import AboutMeBlockContent from '../../content/AboutMeBlockContent.json';
import WhenToStartBlockContent from '../../content/WhenToStartBlockContent.json';
import SessionsBlockContent from '../../content/SessionsBlockContent.json';
import ContactsBlockContent from '../../content/ContactsBlockContent.json';
import { scrollTo } from '../../utils/scroll';
import { useNavigate } from 'react-router-dom';

const Button = lazy(() => import('../../common/Button'));

export const Header: FunctionComponent<HeaderProps> = ({ t, isTrainingsLayout }) => {
  const [isNavVisible] = useState(false);
  const [isSmallScreen] = useState(false);
  const [visible, setVisibility] = useState(false);

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    const navigate = useNavigate();

    const scroll = (id: string) => {
      scrollTo(id);
      setVisibility(false);
    };

    const goTo = (id: string) => {
      navigate(id);
    };

    const addTestIdAttribute = (id: string) => {
      return `header_${id}`;
    };

    return (
      <Fragment>
        {isTrainingsLayout ? (
          <>
            <S.CustomNavLinkSmall onClick={() => scroll('intro')}>
              <S.Span testIdAttribute={addTestIdAttribute('intro')}>Mokymai</S.Span>
            </S.CustomNavLinkSmall>
            <S.CustomNavLinkSmall onClick={() => scroll(AboutMeBlockContent.title)}>
              <S.Span testIdAttribute={addTestIdAttribute(AboutMeBlockContent.title)}>
                {t(AboutMeBlockContent.title)}
              </S.Span>
            </S.CustomNavLinkSmall>
            <S.CustomNavLinkSmall onClick={() => scroll('time-planning')}>
              <S.Span testIdAttribute={addTestIdAttribute('time-planning')}>
                Laiko planavimas
              </S.Span>
            </S.CustomNavLinkSmall>
            <S.CustomNavLinkSmall onClick={() => scroll('mindfulness')}>
              <S.Span testIdAttribute={addTestIdAttribute('mindfulness')}>
                Dėmesingas įsisąmoninimas
              </S.Span>
            </S.CustomNavLinkSmall>
            <S.CustomNavLinkSmall onClick={() => scroll('other-trainings')}>
              <S.Span testIdAttribute={addTestIdAttribute('other-trainings')}>Kiti mokymai</S.Span>
            </S.CustomNavLinkSmall>
            <S.CustomNavLinkSmall
              style={{ width: '180px' }}
              onClick={() => scroll(ContactsBlockContent.title)}
            >
              <S.Span testIdAttribute={addTestIdAttribute(ContactsBlockContent.title)}>
                <Button testIdAttribute="header_contact_button">
                  {t(ContactsBlockContent.title)}
                </Button>
              </S.Span>
            </S.CustomNavLinkSmall>
          </>
        ) : (
          <>
            <S.CustomNavLinkSmall onClick={() => scroll(IntroBlockContent.title)}>
              <S.Span testIdAttribute={addTestIdAttribute(IntroBlockContent.title)}>
                {t(IntroBlockContent.title)}
              </S.Span>
            </S.CustomNavLinkSmall>
            <S.CustomNavLinkSmall onClick={() => scroll(AboutMeBlockContent.title)}>
              <S.Span testIdAttribute={addTestIdAttribute(AboutMeBlockContent.title)}>
                {t(AboutMeBlockContent.title)}
              </S.Span>
            </S.CustomNavLinkSmall>
            <S.CustomNavLinkSmall onClick={() => scroll(WhenToStartBlockContent.title)}>
              <S.Span testIdAttribute={addTestIdAttribute(WhenToStartBlockContent.title)}>
                {t(WhenToStartBlockContent.title)}
              </S.Span>
            </S.CustomNavLinkSmall>
            <S.CustomNavLinkSmall onClick={() => scroll(SessionsBlockContent.title)}>
              <S.Span testIdAttribute={addTestIdAttribute(SessionsBlockContent.title)}>
                {t(SessionsBlockContent.title)}
              </S.Span>
            </S.CustomNavLinkSmall>
            <S.CustomNavLinkSmall onClick={() => goTo('mokymai')}>
              <S.Span testIdAttribute={addTestIdAttribute('trainings')}>Mokymai</S.Span>
            </S.CustomNavLinkSmall>
            <S.CustomNavLinkSmall
              style={{ width: '180px' }}
              onClick={() => scroll(ContactsBlockContent.title)}
            >
              <S.Span testIdAttribute={addTestIdAttribute(ContactsBlockContent.title)}>
                <Button testIdAttribute="header_contact_button">
                  {t(ContactsBlockContent.title)}
                </Button>
              </S.Span>
            </S.CustomNavLinkSmall>
          </>
        )}
      </Fragment>
    );
  };

  return (
    <S.Header>
      <S.Container>
        <Row justify="space-between" gutter={20}>
          <S.LogoContainer to="/" aria-label="homepage" id="homepage">
            <h2 data-cy="header_main">Vidinės kelionės</h2>
          </S.LogoContainer>
          <S.NotHidden>
            <MenuItem />
          </S.NotHidden>
          <S.Burger onClick={showDrawer}>
            <S.Outline />
          </S.Burger>
        </Row>
        <CSSTransition
          in={!isSmallScreen || isNavVisible}
          timeout={350}
          classNames="NavAnimation"
          unmountOnExit
        >
          <Drawer closable={false} visible={visible} onClose={onClose}>
            <Col style={{ marginBottom: '2.5rem' }}>
              <S.Label onClick={onClose}>
                <Col span={12}>
                  <S.Menu>{t('menu')}</S.Menu>
                </Col>
                <Col span={12}>
                  <S.Outline $padding={true} />
                </Col>
              </S.Label>
            </Col>
            <MenuItem />
          </Drawer>
        </CSSTransition>
      </S.Container>
    </S.Header>
  );
};

export default withTranslation()(Header);
