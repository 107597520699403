import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import * as ReactDOM from 'react-dom/client';

import { I18nextProvider } from 'react-i18next';
import 'antd/dist/antd.css';
import i18n from './translation';
import Home from './pages/Home';
import Trainings from './pages/Trainings';
import * as React from 'react';
import GlobalStyles from './globalStyles';
import { getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';

initializeFaro({
  url: process.env.REACT_APP_GRAFANA_FARO_URL,
  app: {
    name: process.env.REACT_APP_GRAFANA_FARO_APP_NAME,
    version: '1.0.0',
    environment: 'production',
  },
  instrumentations: [...getWebInstrumentations(), new TracingInstrumentation()],
});

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/mokymai',
    element: <Trainings />,
  },
]);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <React.Suspense fallback={null}>
      <GlobalStyles />
      <I18nextProvider i18n={i18n}>
        <RouterProvider router={router} />
      </I18nextProvider>
    </React.Suspense>
  </React.StrictMode>,
);
