import styled from 'styled-components';

export const RightBlockContainer = styled.section`
  position: relative;
  padding: 3rem 0 2rem;
  text-align: justify;

  @media only screen and (max-width: 768px) {
    padding: 0rem 0 2rem;
  }
`;

export const Content = styled.p`
  margin: 1.5rem 0 2rem 0;
`;

export const List = styled.li``;

export const IList = styled.ul``;

//@ts-ignore
export const ContentWrapper = styled.div.attrs(() => ({
  'data-cy': 'trainings-intro-left-container',
}))`
  position: relative;
  max-width: 540px;

  @media only screen and (max-width: 480px) {
    margin: 2rem 0;
  }
`;

//@ts-ignore
export const ContentAboutMeWrapper = styled.div.attrs(() => ({
  'data-cy': 'trainings-about-me-right-container',
}))`
  position: relative;
  max-width: 540px;

  @media only screen and (max-width: 480px) {
    margin: 2rem 0;
  }
`;

//@ts-ignore
export const ContentTimePlanningWrapper = styled.div.attrs(() => ({
  'data-cy': 'trainings-time-planning-right-container',
}))`
  position: relative;
  max-width: 540px;

  @media only screen and (max-width: 480px) {
    margin: 2rem 0;
  }
`;
