import { Row, Col } from 'antd';
import { FunctionComponent, lazy } from 'react';
import { withTranslation } from 'react-i18next';
import { Slide } from 'react-awesome-reveal';

import { Image } from '../../../common/Image';

import * as S from './styles';
import { TrainingsBlockProps } from './types';

const ContactFrom = lazy(() => import('../../ContactForm'));

export const TrainingsBlock: FunctionComponent<TrainingsBlockProps> = ({
  title,
  paragraphs,
  t,
  id,
}) => {
  return (
    <>
      <S.RightBlockContainer>
        <Row justify="space-between" align="middle" id={'intro'}>
          <Col lg={11} md={11} sm={12} xs={24}>
            <Slide triggerOnce direction="left">
              <h6 data-cy="header_trainings_intro_title">Mokymai</h6>
            </Slide>
          </Col>
        </Row>
        <Row justify="space-between" align="middle" id={'intro'}>
          <Col lg={11} md={11} sm={11} xs={24}>
            <Slide triggerOnce direction="left">
              <S.ContentWrapper>
                {/* {paragraphs?.map((value, index) => (
              <S.Content key={index}>{t(value)}</S.Content>
            ))} */}
                <S.Content>
                  Mokymai, kurie orientuoti į asmeninį augimą, geresnį savęs pažinimą ir ilgalaikį
                  pokytį. Siekiant šių tikslų skatinamas kiekvieno dalyvio įsitraukimas, nemažai
                  dėmesio skiriama praktinėms užduotims atlikti ir dirbti grupėse. Mokymai gali būti
                  individualizuojami pagal Jūsų organizacijos poreikius.
                </S.Content>
              </S.ContentWrapper>
            </Slide>
          </Col>{' '}
          <Col lg={11} md={11} sm={12} xs={24}>
            <div data-cy="trainings-intro-right-container">
              <Slide triggerOnce direction="right">
                <Image src="focus.jpg" width="100%" height="100%" idAttribute="img_focus" />
              </Slide>
            </div>
          </Col>
        </Row>
        <Row justify="space-between" align="middle" id={'about-me'}>
          <Col lg={11} md={11} sm={12} xs={24}>
            <Slide triggerOnce direction="left">
              <h6 data-cy="header_trainings_about_me_title">Apie mane</h6>
            </Slide>
          </Col>
        </Row>
        <Row justify="space-between" align="middle" id={'about-me'}>
          <Col lg={11} md={11} sm={12} xs={24}>
            <div data-cy="trainings-about-me-left-container">
              <Slide triggerOnce direction="left">
                <Image src="myself.jpg" width="100%" height="100%" idAttribute="img_myself" />
              </Slide>
            </div>
          </Col>
          <Col lg={11} md={11} sm={11} xs={24}>
            <Slide triggerOnce direction="right">
              <S.ContentAboutMeWrapper>
                {/* {paragraphs?.map((value, index) => (
                <S.Content key={index}>{t(value)}</S.Content>
              ))} */}
                <S.Content>
                  Esu psichologė Eglė Balčiūtė-Biriukova. Vedu praktinius mokymus, užsiėmimus,
                  orientuotus į geresnį savęs pažinimą, dėmesingo įsisąmoninimo{' '}
                  <i>(angl. mindfulness)</i> technikų taikymą.
                  <S.ContentWrapper>
                    <S.Content>Išsilavinimas</S.Content>
                  </S.ContentWrapper>
                  <S.ContentWrapper>
                    <S.Content>
                      2009-2016 – įgyti Vilniaus universiteto psichologijos bakalauro ir klinikinės
                      psichologijos magistro laipsniai
                    </S.Content>
                  </S.ContentWrapper>
                  <S.ContentWrapper>
                    <S.Content>
                      2020–2022 – baigta Pradinių psichoterapinių įgūdžių mokymo programa (Praktinės
                      psichologijos studijų centras, Lietuvos analitinės psichologijos asociacija)
                    </S.Content>
                  </S.ContentWrapper>
                  <S.ContentWrapper>
                    <S.Content>
                      2020–2022 – baigta podiplominių studijų programa „Įsisąmoninimu (mindfulness)
                      grįstos kognityvinės ir elgesio terapijos pagrindai“ (LSMU Neuromokslų
                      institutas)
                    </S.Content>
                  </S.ContentWrapper>
                  <S.ContentWrapper>
                    <S.Content>
                      nuo 2023 sertifikuota įsisąmoninimu grįstos psichologijos (angl. mindfulness)
                      intervencijų mokytoja
                    </S.Content>
                  </S.ContentWrapper>
                  <S.ContentWrapper>
                    <S.Content>
                      2024 – baigta specialistams skirta emocinių reguliacijos įgūdžių lavinimo
                      grupė, paremta dialektinės elgesio terapijos (angl. DBT) metodikomis ir
                      principais (36 ak. val.)
                    </S.Content>
                  </S.ContentWrapper>
                </S.Content>
              </S.ContentAboutMeWrapper>
            </Slide>
          </Col>
        </Row>
      </S.RightBlockContainer>
      <S.RightBlockContainer>
        <Row justify="space-between" align="middle" id={'time-planning'}>
          <Col lg={11} md={11} sm={12} xs={24}>
            <Slide triggerOnce direction="left">
              <h6 data-cy="header_time_planning_title">Laiko planavimas</h6>
            </Slide>
          </Col>
        </Row>
        <Row justify="space-between" align="middle" id={'time-planning'}>
          <Col lg={11} md={11} sm={11} xs={24}>
            <Slide triggerOnce direction="left">
              <S.ContentTimePlanningWrapper>
                {/* {paragraphs?.map((value, index) => (
              <S.Content key={index}>{t(value)}</S.Content>
            ))} */}
                <S.Content>
                  Suprasti, „kur dingsta laikas“, o gal tiksliau, kam renkamės jį skirti, yra labai
                  svarbu. Mokymuose aptariame laiko audito svarbą, mokomės, kaip išskirti
                  prioritetus, kalbame apie lūkesčius sau ir aplinkai, juos peržiūrime.
                </S.Content>
              </S.ContentTimePlanningWrapper>
            </Slide>
          </Col>{' '}
          <Col lg={11} md={11} sm={12} xs={24}>
            <div data-cy="trainings-time-planning-right-container">
              <Slide triggerOnce direction="right">
                <Image src="clock.jpeg" width="100%" height="100%" idAttribute="img_clock" />
              </Slide>
            </div>
          </Col>
        </Row>
        <Row justify="space-between" align="middle" id={'time-planning'}>
          <div data-cy="trainings-time-planning-bottom-container">
            <Slide triggerOnce direction="up">
              <S.Content>Galimos mokymų formos: </S.Content>
              <S.List>
                Kelių akademinių valandų seminaras, kur trumpai pristatomos įvairios laiko
                planavimo, prioritetų kėlimo technikos, kelios „taisyklės“, kurios gali padėti
                darbe. Šis įvadas skirtas susipažinti su technikomis, kurias vėliau taikant
                kasdienėje veikloje ugdomas įgūdis planuoti laiką.
              </S.List>
              <S.List>
                Tęstiniai mokymai gali būti kaip tik tai, ko reikia Jūsų komandai. Formuoti įgūdį
                vienam gali būti sudėtinga, dėl to darbas grupėje, dalinimasis savo bei mokymasis iš
                kitų patirties gali būti itin naudingi. Susitikimuose daug dėmesio skiriama
                praktinėms veikloms, dalyviams pageidaujant, tiesiogiai su darbine veikla
                susijusiems sunkumams aptarti.
              </S.List>
              <S.List>
                Dar viena mokymosi forma – individualūs susitikimai, kur aptariami iššūkiai ir
                galimybės ieškant atsakymų, kaip spręsti kylančius sunkumus planuojant laiką.
              </S.List>
            </Slide>
          </div>
        </Row>
      </S.RightBlockContainer>
      <S.RightBlockContainer>
        <Row justify="space-between" align="middle" id={'mindfulness'}>
          <Col lg={14} md={11} sm={12} xs={24}>
            <Slide triggerOnce direction="left">
              <h6 data-cy="header_trainings_mindfulness_title">
                Dėmesingo įsisąmoninimo praktikos
              </h6>
            </Slide>
          </Col>
        </Row>
        <Row justify="space-between" align="middle" id={'mindfulness'}>
          <Col lg={11} md={11} sm={12} xs={24}>
            <div data-cy="trainings-mindfulness-left-container">
              <Slide triggerOnce direction="left">
                <Image src="sand.jpeg" width="100%" height="100%" idAttribute="img_sand" />
              </Slide>
            </div>
          </Col>
          <Col lg={11} md={11} sm={11} xs={24}>
            <div data-cy="trainings-mindfulness-right-container">
              <Slide triggerOnce direction="right">
                <S.ContentWrapper>
                  {/* {paragraphs?.map((value, index) => (
              <S.Content key={index}>{t(value)}</S.Content>
            ))} */}
                  <S.Content>
                    Dėmesingas įsisąmoninimas <i>(angl. mindfulness)</i> gali būti labai naudingas
                    įgūdis darbe. Dėmesingo įsisąmoninimo praktikos padeda sustoti chaotiškoje
                    rutinoje, grįžti į čia ir dabar akimirką, o tai padeda sumažinti nerimo ir
                    streso lygį. Praktikų, kurios moko dėmesingo įsisąmoninimo{' '}
                    <i>(angl. mindfulness)</i> yra pačių įvairiausių. Dėl to mokymuose siekiama
                    susipažinti su kuo įvairesnėmis praktikomis, kurias vėliau būtų galima taikyti
                    skirtingose situacijose, pavyzdžiui, pasirenkant pagal trukmę ar veiklą, pvz.,
                    taikyti praktikas valgant.
                  </S.Content>
                </S.ContentWrapper>
              </Slide>
            </div>
          </Col>
        </Row>
        <Row justify="space-between" align="middle" id={'mindfulness'}>
          <div data-cy="trainings-mindfulness-bottom-container">
            <Slide triggerOnce direction="up">
              <S.Content>Galimos mokymų formos:</S.Content>
              <S.List>
                Kelių akademinių valandų seminaras, kur trumpai supažindinama su pagrindiniais
                dėmesingo įsisąmoninimo principais, kartu atliekamos kelios nesudėtingos praktikos,
                kurias mokymų dalyviai gali toliau taikyti kasdien.
              </S.List>
              <S.List>
                Tęstiniai mokymai, pakartotiniai susitikimai gali padėti formuoti dėmesingo
                įsisąmoninimo praktikų įgūdžius, padėti neprarasti motyvacijos juos taikyti.
                Susitikimuose daug dėmesio skiriama įvairioms dėmesingo įsisąmoninimo praktikoms,
                dalinimuisi grupelėse, kur dalyviai ne tik mokosi iš savo patirties, ja dalinasi,
                tačiau, klausydamiesi kitų dalyvių patirčių, gali suprasti ir savąsias.
              </S.List>
            </Slide>
          </div>
        </Row>
      </S.RightBlockContainer>

      <S.RightBlockContainer>
        <Row justify="space-between" align="middle" id={'other-trainings'}>
          <Col lg={11} md={11} sm={12} xs={24}>
            <Slide triggerOnce direction="left">
              <h6 data-cy="header_trainings_other_trainings_title">Kiti mokymai</h6>
            </Slide>
          </Col>
        </Row>
        <Row justify="space-between" align="middle" id={'other-trainings'}>
          <Col lg={11} md={11} sm={11} xs={24}>
            <div data-cy="trainings-other-trainings-left-container">
              <Slide triggerOnce direction="left">
                <S.ContentWrapper>
                  {/* {paragraphs?.map((value, index) => (
              <S.Content key={index}>{t(value)}</S.Content>
            ))} */}
                  <S.Content>
                    Jeigu svarstote apie mokymus tema, kurios čia neradote, kviečiu susisiekti.
                  </S.Content>
                </S.ContentWrapper>
              </Slide>
            </div>
          </Col>{' '}
          <Col lg={11} md={11} sm={12} xs={24}>
            <div data-cy="trainings-other-trainings-right-container">
              <Slide triggerOnce direction="right">
                <Image src="cherry.jpeg" width="100%" height="100%" idAttribute="img_cherry" />
              </Slide>
            </div>
          </Col>
        </Row>
      </S.RightBlockContainer>

      <S.RightBlockContainer>
        <Row justify="start" align="top" id={'contacts'}>
          <Col lg={11} md={11} sm={5} xs={24}>
            <div data-cy="trainings-contacts-left-container">
              <Slide triggerOnce direction="left">
                <h5 data-cy="header_trainings_contacts_title">Kontaktai</h5>

                <S.Content>Dėl mokymų kreipkitės:</S.Content>
                <S.IList>
                  ✉{' '}
                  <a href="mailto:egles.balciutes@gmail.com" data-cy="contacts_link_email">
                    egles.balciutes@gmail.com
                  </a>
                </S.IList>
                <S.IList>
                  ✆{' '}
                  <a href="tel:+37061941275" data-cy="contacts_link_phone">
                    +37061941275
                  </a>
                </S.IList>

                <S.Content>{t('contacts-social-media')}:</S.Content>
                <S.IList>
                  <iframe
                    title="facebook"
                    src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fvidineskeliones.lt&tabs&width=300&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                    width="340"
                    height="100"
                    style={{
                      border: 'none',
                      overflow: 'hidden',
                    }}
                    scrolling="no"
                    frameBorder="0"
                    allowFullScreen={true}
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                  ></iframe>
                </S.IList>
              </Slide>
            </div>
          </Col>
          <Col lg={11} md={11} sm={5} xs={24}>
            <div data-cy="trainings-contacts-right-container">
              <ContactFrom id="contact" title="contacts-form" direction="right" />
            </div>
          </Col>
        </Row>
      </S.RightBlockContainer>
    </>
  );
};

export default withTranslation()(TrainingsBlock);
